<template>
  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f" min-height="280" >
    <MainModal
        :main="{ component: 'TariffGroup', title: 'Групи тарифу' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_tariff_group)"
        :modal="show_tariff_group_dialog"
        @updateItemModal="tariffGroupUpdateItemModal"
    />
    <v-card-title class="pa-0">
      <v-toolbar elevation="0" class="pa-0">
        <v-toolbar-title class="toolbar-work-place-title">
          Групи тарифу
        </v-toolbar-title>
        <v-spacer/>
        <v-tooltip bottom color="success">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="openTariffGroupCreateDialog"
                   v-bind="attrs"
                   v-on="on"
                   class="grey lighten-4 mr-1">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Створити нову групу тарифу</span>
        </v-tooltip>
      </v-toolbar>
    </v-card-title>
    <v-data-table
        :headers="groupHeaders"
        :items="tariffGroups"
        hide-default-footer
        class="custom-table custom-table-1"
        @click:row="onTariffGroupItemClick"
    >
      <template v-slot:item.icon>
        <v-icon size="26" color="success" class="mr-2">
          mdi-format-list-numbered
        </v-icon>
      </template>
      <template v-slot:item.date_start="{ item }">
        {{ item.date_start | formatDate }}
      </template>
      <template v-slot:item.date_end="{ item }">
        {{ item.date_end | formatDate }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>

import MainModal from "@/components/modal/MainModal";
import {mapActions, mapGetters} from "vuex";
import {FETCH_TARIFF_GROUP_BY_FLAT, FETCH_TARIFF_GROUP_SELECT} from "@/store/actions/tariff_group";
export default {
  name: "HWP_TariffGroupsTable",
  components: { MainModal },
  props: {
    inner_person_hash: {
      type: String,
      default() {
        return ''
      }
    }
  },
  computed: {
    ...mapGetters({
      tariffGroups: 'getTariffGroup'
    })
  },
  data() {
    return {
      groupHeaders: [
        { text: '', value: 'icon', width: 26 },
        { text: 'Назва групи', value: 'tariff_group_name' },
        { text: 'Початок', value: 'date_start' },
        { text: 'Закінчення', value: 'date_end' },
        { text: 'Автор', value: 'owner_name' },
      ],
      selected_tariff_group: {},
      show_tariff_group_dialog: false,
    }
  },
  methods: {
    ...mapActions({
      fetchTariffGroups: FETCH_TARIFF_GROUP_BY_FLAT,
      fetchTariffGroupSelect: FETCH_TARIFF_GROUP_SELECT
    }),
    tariffGroupUpdateItemModal(payload) {
      this.show_tariff_group_dialog = false
      this.selected_tariff_group = {}

      if (payload) {
        if (payload.payload === 'fetch') {
          this.$emit('fetch')
        }
      }
    },
    openTariffGroupCreateDialog() {
      this.selected_tariff_group = {
        person_hash: this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash}
      this.show_tariff_group_dialog = true
    },
    onTariffGroupItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
      local_payload.tariff_group_type = local_payload.tariff_group_type_id

      this.selected_tariff_group = local_payload
      this.show_tariff_group_dialog = true
    },
  },
  created() {
    const person_hash = this.$route.params.person_hash ? this.$route.params.person_hash : this.inner_person_hash
    if (person_hash) {
      this.fetchTariffGroups(person_hash)
          .then(res => {
            if (res) {
              this.fetchTariffGroupSelect(person_hash)
            }
          })
    }
  }
}
</script>

<style scoped lang="scss">

</style>